import { Ability, RawRuleOf } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Actions,
  Subjects,
  SubscriptionTypesEnum,
} from '../../../server/abilities/UserAbilityEnums';
import {
  Role,
  SubscriptionPlanTier,
  TeamRole,
} from '../../../server/graphql/__generated.types';
import { useUserSubscription } from '../../components/Account/operation.generated';
import { checkSubscriptionPause } from '../../components/Account/UserSubscriptionCard/churnkey';
import createSigoutProps from '../../lib/createSignoutProps';
import isBrowser from '../../util/isBrowser';
import { setUserContext as setUserContextForSentry } from '../../util/sentry';
import {
  UserContextQueryHookResult,
  useUserContextQuery,
} from './operation.generated';

export { Actions };
export { Subjects };

type ContextState = UserContextQueryHookResult;

export type UserAbility = Ability<[Actions, Subjects]>;

const {
  AUTH0_MAKER_ROLE_ID,
  AUTH0_CREATIVE_ROLE_ID,
  AUTH0_PRODUCER_ROLE_ID,
  AUTH0_BETA_ROLE_ID,
  AUTH0_DESIGNER_BETA_ROLE_ID,
  AUTH0_TEAM_ENTERPRISE_ROLE_ID,
} = getConfig().publicRuntimeConfig;

/**
 * Abilities Context
 */
export const unpackAbility = (ability: string) => {
  try {
    return JSON.parse(ability).A as RawRuleOf<UserAbility>[];
  } catch (_error) {
    throw new Error(
      'Error unpacking `UserAbility` within `UserAbilitiesContext`'
    );
  }
};
export const UserAbilitiesContext = createContext<UserAbility>(new Ability());
UserAbilitiesContext.displayName = 'UserAbilitiesContext';

export const Can = createContextualCan<UserAbility>(
  UserAbilitiesContext.Consumer
);

export function useUserAbilitiesContext() {
  return useContext(UserAbilitiesContext);
}

/**
 * User Context
 */
export const UserContext = createContext<ContextState>(
  // @ts-ignore No default value, force use under Provider
  null
);
UserContext.displayName = 'UserContext';

export function useUserContext(): ContextState {
  const userContext: ContextState | null = useContext(UserContext);
  if (!userContext)
    throw new Error(
      `Attempting to use UserContext outside of the <UserProvider />`
    );
  return userContext;
}

/**
 * @todo [team_expansion] deprecate this
 */
export function useUserTeam() {
  const { data } = useUserContext();
  return data?.user.team;
}

export function useUserTeamRole() {
  const { data } = useUserContext();
  return data?.user.team?.viewersRoleOnTeam;
}

/**
 * @description delineates whether a user is a a traditional team user (multi-seat)
 * single-seat users would return false
 */
export function useIsTeamMultiSeat() {
  const { data } = useUserContext();
  return Boolean(data?.user.subscription?.plan?.isMultiSeat);
}

/**
 * @description check is the current user is a managed user
 */
export function useIsManagedUser() {
  const { data } = useUserContext();
  return Boolean(data?.user.subscription?.plan?.managed);
}

export function useIsUserTeamAdminOrOwner() {
  const teamData = useUserTeam();
  if (!teamData) {
    return false;
  }
  return isTeamAdminOrOwner(teamData);
}

/**
 * @note this utility checks if enterprise user on new system
 */
export function isEnterpriseUser(planName?: string) {
  if (!planName) {
    return false;
  }
  return planName === 'Enterprise';
}

export const isTeamAdminOrOwner = (teamData: any) => {
  return (
    teamData?.viewersRoleOnTeam === TeamRole.Admin ||
    teamData?.viewersRoleOnTeam === TeamRole.Owner
  );
};

export function hasTrialRole(roles: Role[]) {
  return roles.some(role => role.id === AUTH0_BETA_ROLE_ID);
}

/**
 * @todo apply a more stable tier identity in the future when available
 * @note we are not fuzzy inspecting display name here due to designer trial and business trial
 */
export function useIsTrialUser() {
  const { data } = useUserContext();
  return (
    (data?.user.roles && hasTrialRole(data.user.roles)) ||
    data?.user.subscription?.plan?.id === 'WELLSAID_BETA_TRIAL' ||
    data?.user.subscription?.plan?.tier === SubscriptionPlanTier.Trial
  );
}

// todo: at some point we may want to register business trial here

/**
 * @todo apply a more stable tier identity in the future when available
 * note this does not need to be handled at this time AFAIK
 */
export function useIsDesignerTrial() {
  const { data } = useUserContext();
  return (
    data?.user.roles.some(role => role.id === AUTH0_DESIGNER_BETA_ROLE_ID) ||
    data?.user.subscription?.plan?.tier === SubscriptionPlanTier.DesignerTrial
  );
}

/**
 * @todo apply a more stable tier identity in the future when available
 */
export function useIsProducerSubscription() {
  const { data } = useUserContext();
  return (
    data?.user.roles.some(role => role.id === AUTH0_PRODUCER_ROLE_ID) ||
    data?.user.subscription?.plan?.tier === SubscriptionPlanTier.Producer
  );
}

export function hasMakerRole(roles: Role[]) {
  return roles.some(role => role.id === AUTH0_MAKER_ROLE_ID);
}

/**
 * @todo apply a more stable tier identity in the future when available
 * @note when mixed teams are supported, this will need to be updated
 */
export function useIsMaker() {
  const { data } = useUserContext();
  return (
    (data?.user.roles && hasMakerRole(data.user.roles)) ||
    data?.user.subscription?.plan?.tier === SubscriptionPlanTier.Maker
  );
}

/**
 * @todo apply a more stable tier identity in the future when available
 * @note when mixed teams are supported, this will need to be updated
 */
export function useIsCreative() {
  const { data } = useUserContext();
  return (
    data?.user.roles.some(role => role.id === AUTH0_CREATIVE_ROLE_ID) ||
    data?.user.subscription?.plan?.tier === SubscriptionPlanTier.Creative
  );
}

export function hasEnterpriseRole(roles: Role[]) {
  return roles.some(role => role.id === AUTH0_TEAM_ENTERPRISE_ROLE_ID);
}

export function useIsEnterpriseUser() {
  const { data } = useUserContext();
  return (
    (data?.user.roles && hasEnterpriseRole(data.user.roles)) ||
    data?.user.subscription?.plan?.tier === SubscriptionPlanTier.Enterprise
  );
}

export function useIsWellSaidAdmin() {
  const { data } = useUserContext();
  return data?.user.isAdmin || false;
}

export function useUserHasTeam() {
  return Boolean(useUserTeam());
}

export const unpackAbilities = (userAbilities: string) => {
  const unpackedAbility = unpackAbility(userAbilities);
  return new Ability(unpackedAbility) as UserAbility;
};

export const UserProvider: React.FC = ({ children }) => {
  const [userId, setUserId] = useState<string | null>(null);
  const [churnkeyLoaded, setChurnkeyLoaded] = useState<boolean>(
    isBrowser && window.churnkey
  );
  const query = useUserContextQuery();
  const { data: userSubscription } = useUserSubscription();

  useEffect(() => {
    if (query.data && query.data.user) {
      setUserId(query.data.user.id);
    } else {
      setUserId(null);
    }
  }, [query.data]);

  useEffect(() => {
    setUserContextForSentry(userId);
  }, [userId]);

  const userAbilities = query.data?.user.abilities;
  const memoizedAbility = useMemo<UserAbility>(() => {
    try {
      if (!userAbilities) return new Ability();
      return unpackAbilities(userAbilities);
    } catch (error) {
      return new Ability();
    }
  }, [userAbilities]);

  const ability = memoizedAbility;

  useEffect(() => {
    const onChurnkeyLoad = () => {
      setChurnkeyLoaded(true);
    };
    const script = document.getElementById('churnkey-script-loader');
    script?.addEventListener('load', onChurnkeyLoad);
    return () => {
      script?.removeEventListener('load', onChurnkeyLoad);
    };
  }, []);

  useEffect(() => {
    /**
     * Checks the status of the Stripe subscription and if it's paused,
     * a pause wall (handled by Churnkey) will be presented to the user.
     * @see https://docs.churnkey.co/pause-wall
     */
    if (
      churnkeyLoaded &&
      ability.can(Actions.UseFeature, Subjects.Churnkey) &&
      userSubscription?.user.subscription
    ) {
      checkSubscriptionPause({
        subscription: userSubscription?.user.subscription,
        handleLogout: () => {
          createSigoutProps().onClick();
        },
      });
    }
  }, [ability, churnkeyLoaded, userSubscription?.user.subscription]);

  return (
    <UserContext.Provider value={query}>
      <UserAbilitiesContext.Provider value={memoizedAbility}>
        {children}
      </UserAbilitiesContext.Provider>
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
